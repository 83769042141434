import React from 'react';
import styles from './index.module.css'

const Banner = ({ bannerUrl }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          智慧生活
          <br/>
          一部手机，开启高品质社区生活体验
        </div>
        <div className={styles.img1}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/January/29/882f25a02a4d4f399c9fdf728c741422"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img2}>
          <img
            src={
              bannerUrl
                ? bannerUrl
                : "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/f747e6e1c4a94d4db7979d23596678e2"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img3}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/02a9588afc0146a1a8c605c724078efc"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.img4}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/80cb18fc838e49428550fd2f0423620f"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={styles.imgLogo}>
          <img
            src={
              "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/bb4a4d8612024a72b6a907597e073f31"
            }
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  )
}

export default Banner
