import React from 'react';
import styles from './index.module.css'

const Steward =()=>{
    return(
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.img}>
                    <img
                        src={
                        "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/fab1ebe8fefa4f848a1a47791a044998"
                        }
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
                <div className={styles.imgLogo}>
                    <img
                        src={
                        "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/02b91addd73e428b9c0aa1f4b475fd35"
                        }
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
                <div className={styles.imgLogoSmart}>
                    <img
                        src={
                        "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/064aa875957a4c2db1e54cc08dcab43e"
                        }
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
                <div className={styles.right}>
                    <div className={styles.title}>物业管家</div>
                    <div className={styles.titleBox}>
                        <div className={styles.describe}>
                            线上报事报修，工单进度实时可查
                        </div>
                        <div className={styles.describe}>
                            物业通知，APP端及时推送
                        </div>
                        <div className={styles.describe}>
                        在线缴费，账目清晰、免上门
                        </div>
                    </div>
                    <div className={styles.logoBox}>
                        <div className={styles.titleLogo}>
                            <img
                                src={
                                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/000981dee38e453da8512b06dc2224e5"
                                }
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>
                        <div className={styles.titleLogo}>
                            <img
                                src={
                                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/f90972da926f4069b61bc2101f38212a"
                                }
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>
                        <div className={styles.titleLogo}>
                            <img
                                src={
                                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/5fb9bc03e9504e4385804d29504b6def"
                                }
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>
                        <div className={styles.titleLogo}>
                            <img
                                src={
                                "https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/81838ff3b0ba443a84a177867639d4c4"
                                }
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Steward;