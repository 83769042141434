import React from "react"

import Layout from "../components/layout/index"
import Banner from "../components/Smart-life/banner/index"
import BlockRight from "../components/smart-life/components/block-right/index"
import BlockLeft from "../components/smart-life/components/block-left/index"
import Steward from "../components/smart-life/steward/index"

import SEO from "../components/seo"

const SmartLife = () => {
  const parkJson = {
    imgLeft: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/3c16ca9f18bf4b54905ad7b92ab4a352',
    imgLogoLeft: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/f075545a5bb046ecbebd84023cd684cf',
    title: '智慧停车',
    descList: ['业主车牌自动识别，快速过闸','外来车辆进库智慧分流，停放有序','业主车牌自动识别，快速过闸'],
    iconList: ['https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/000981dee38e453da8512b06dc2224e5',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/46a94c76f1d44fcf9e70ab070e8fdab5',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/9d8c23f9130d4e028f8dddbee2d0af31']
  }

  const currentJson = {
    imgRight: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/3c234f7a942544a9bfeacba5486d86cd',
    imgLogoRight: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/44593b8d9f7a465299f0f3db110e3a5d',
    title: '社区通行',
    descList: ['业主通过人脸识别、APP扫码或NFC功能，快速进出社区、单元楼','访客通过业主远程开门或扫描业主授权动态码进出社区、单元楼达'],
    iconList: ['https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/3fb0f9263a9c406ab3f16a504d7bdcea',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/26ab6e48e0a547b99977a99e4e98ac8e',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/903317816b314cd090823e2f25a9a8cf',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/86220b023bd54fdc8c3775269ff56a12']
  }

  const squareJson = {
    imgRight: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/cad12b56a4eb4462b884994504c59924',
    imgLogoRight: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/9b24171d903c49339a223b4f36d0b1d5',
    title: '服务广场',
    descList: ['餐饮、衣护、保洁、宠物、育儿、娱乐，周边服务个性化推荐','在线查询、优惠专享、一键下单、服务到家'],
    iconList: ['https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/31eb36af1bd44bda8e030f4199852ff9',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/33e2bec2e6fd4bd58128b834031c28db',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/884e5aaa733847fdb048d5474f349dbd',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/17acf358c2874b7a875afeaf93eb46ca',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/0b99c142789a444aad178ec216aa258b',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/26/08d884ac31bf47029c7925de31e4f98e']
  }

  const mallJson = {
    imgLeft: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/f6f0f8e9ff5a47e09f5d925207ba0336',
    imgLogoLeft: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/a3f07d3382114e2493fe91790e57c091',
    title: '社区商城',
    descList: ['在线社区商城，一键下单','社区仓库实现当日送达'],
    iconList: ['https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/54a2065c29a440918272a1faca17b7f2',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/fefee56e88594430922e5b71cc9a1007',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/91a2b0947c114c8398a678715a2671ed']
  }

  const neighborhoodJson = {
    imgRight: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/24fda360c0c04e6d91b7ed35e4a3e99d',
    imgLogoRight: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/2e398065d18e4e00842916fa960a7646',
    title: '邻里社交',
    descList: ['手机APP公共议题讨论、交友互动','邻里互助、闲置交换等社交活动，打破社区人际隔阂'],
    iconList: ['https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/c1dd5451154443d5acf5fc5c5ff2a7a6',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/0ccd89afb2514662b327ea9d80b32e13',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/63647d20a1cd45489646c6ecfb686065']
  }

  const workJson = {
    imgLeft: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/a81b2e841b714989bef8adf795756dba',
    imgLogoLeft: 'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/30/57eaa837ca764eb58575f44ea4e1f451',
    title: '办事直通车',
    descList: ['政府资讯及时推送','政府政策快速查询'],
    iconList: ['https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/00ff8eb91193493195d1e8e055582ab4',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/d8fe66f660cf4340988aed9fe70bcf4e',
              'https://static.lianmeihu.com/files/lmh_dev/img/x-uploader/2021/March/29/e75ed00005ed410392465457b7bb723e']
  }
  return (
    <Layout page="SmartLife">
      <SEO title="联每户" />
      <Banner/>
      <BlockLeft json={parkJson}/>     
      <BlockRight json={currentJson}/>
      <Steward/> 
      <BlockRight json={squareJson}/>
      <BlockLeft json={mallJson}/>
      <BlockRight json={neighborhoodJson}/>
      <BlockLeft json={workJson}/>
    </Layout>
  )
}
  export default SmartLife