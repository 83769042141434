import React from 'react';
import styles from './index.module.css'

const BlockRight =(props)=>{
    const {json}=props
    return(
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <div className={styles.title}>{json.title}</div>
                    <div className={styles.titleBox}>
                        {json.descList.map((text)=>{
                            return (<div className={styles.describe}>
                                {text}
                            </div>)
                        })}
                    </div>
                    <div className={styles.logoBox}>
                        {json.iconList.map((text)=>{
                            return (<div className={styles.titleLogo}>
                                <img src={text} alt=""/>
                            </div>)
                        })}
                    </div>
                </div>
                <div className={styles.img}>
                    <img
                        src={
                            json.imgRight
                        }
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
                <div className={styles.imgLogo}>
                    <img
                        src={
                            json.imgLogoRight
                        }
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
            </div>
        </div>
    )
}

export default BlockRight;